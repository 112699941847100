<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            TEAM <vuexy-logo /> SEVEN
          </h2>
        </b-link>

        <b-card-title
          class="mb-1 text-center font-medium-2 text-success"
        >
          2-FACTOR
        </b-card-title>
        <b-card-text class="mb-2" />

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="mobile"
              label="Mobile Number"
            >
              <validation-provider
                #default="{ errors }"
                name="mobile"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-mobile"
                    v-model="maskedMobile"
                    name="login-mobile"
                    :state="errors.length > 0 ? false:true"
                    placeholder="mobile is empty, login please"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      class="font-small-2"
                      @click="requestSMSCode"
                    >
                      Send SMS
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- factorCode -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-code">Code</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="code"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':true"
                >
                  <b-form-input
                    id="login-code"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="login-factorCode"
                    placeholder="code"
                    autofocus
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              style="margin-top: 40px; margin-bottom: 20px;"
              type="submit"
              block
              @click="validationForm"
            >
              Confirm
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { LOGIN, CODE_CONFIRM } from '@/store/auth/action'
// import { SIGNIN_LOGS } from '@/store/logs/action'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_IP_ADMIN } from '@/store/settings/action'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import crypto from 'crypto'
import store from "@/store";
import {watch} from "@vue/composition-api";

const settingsStore = createNamespacedHelpers('settingsStore')
const authStore = createNamespacedHelpers('authStore')
// const logStore = createNamespacedHelpers('logStore')

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const $userMobile = localStorage.getItem('mobile')
    const $maskedMobile = $userMobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    return {
      userMobile: $userMobile,
      maskedMobile: $maskedMobile,
      smsCode: '',
      code: '142178',
      confirm: '',
      status: '',
      // validation rules
      required,
      // client info
      site: 'Admin',
    }
  },
  // created() {
  //   watch(
  //     () => store.getters['authStore/isUser'],
  //     res => {
  //       if (res) {
  //         console.log('res ::', res)
  //       } else {
  //         console.log('res ::', res)
  //         router.replace('/login')
  //       }
  //     },
  //   )
  // },
  mounted() {
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetIpAdmin: 'fetchSetIpAdmin',
    }),
  },
  methods: {
    ...authStore.mapActions({
      // login: LOGIN,
      codeConfirm: CODE_CONFIRM,
    }),
    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      console.log('success :: ', success)
      const confirm = await this.confirmCode()
      console.log('confirm ::', confirm)

      if (success && true) {
        try {
          await this.codeConfirm()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '2Factor Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          console.log('catch error : ', error)
        }
      } else {
        this.toast('2Factor Fail', 'check the SMS code', 'primary')
      }
    },
    requestSMSCode() {
      const msg = {
        Message: `인증코드를 화면에 입력해주세요 [${this.generateCode()}]`,
        PhoneNumber: `+82${this.userMobile}`, // 수신자 전화번호 (국가 코드 포함)
      }
      this.$sns.publish(msg, (err, data) => {
        if (err) {
          this.toast('SMS Fail', 'server is busy', 'primary')
          // console.log('SMS 보내기 실패:', err)
        } else {
          this.toast('SMS Sent', 'check your phone & input the code', 'info')
          // console.log('SMS 보내기 성공:', data.MessageId)
        }
      })
    },
    // 6자리 인증 코드 생성 함수
    generateCode() {
      const smsCode = parseInt(crypto.randomBytes(3).toString('hex'), 16) % 1000000
      this.smsCode = smsCode.toString().padStart(6, '0')
      return this.smsCode
    },
    toast(msg, texts, variants) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          text: texts,
          icon: 'BellIcon',
          variant: variants,
        },
      })
    },
    async confirmCode() {
      // console.log('input code ::', this.code)
      // console.log('sms code ::', this.smsCode)
      if (this.code === this.smsCode) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
